import { render, staticRenderFns } from "./elderlyCareFacilitiesDetail.vue?vue&type=template&id=78cd7456&scoped=true&"
import script from "./elderlyCareFacilitiesDetail.vue?vue&type=script&lang=js&"
export * from "./elderlyCareFacilitiesDetail.vue?vue&type=script&lang=js&"
import style0 from "./elderlyCareFacilitiesDetail.vue?vue&type=style&index=0&id=78cd7456&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78cd7456",
  null
  
)

export default component.exports