<template>
    <div v-loading="loading">
        <!--顶部面包靴-->
        <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/institutionalInformation/elderlyCareFacilities' }" class="fs20">社区服务养老设施</el-breadcrumb-item>
      <el-breadcrumb-item class="fs20">{{'查看'}}</el-breadcrumb-item>
    </el-breadcrumb> -->
        <div class="contentDetail">
            <el-descriptions title="基本信息" colon :column="2" size="">
                <el-descriptions-item label="机构名称">{{ dataInfo.organizationName || '--' }}</el-descriptions-item>
                <el-descriptions-item label="统一社会信用代码">{{ dataInfo.socialCreditCode || '--' }}</el-descriptions-item>
                <el-descriptions-item label="行政区划">{{ dataInfo.administrativeDivision || '--' }}</el-descriptions-item>
                <el-descriptions-item label="机构建立时间">{{ formatDate(dataInfo.institutionCreateTime) || '--' }}</el-descriptions-item>
                <el-descriptions-item label="通信地址">{{ dataInfo.mailingAddress || '--' }}</el-descriptions-item>
            </el-descriptions>
            <el-descriptions title="负责人信息" colon :column="2" size="">
                <el-descriptions-item label="法人或负责人">{{ dataInfo.juridicalPerson || '--' }}</el-descriptions-item>
                <el-descriptions-item label="法人或负责人证件号码">{{ dataInfo.juridicalPersonIdCard || '--' }}</el-descriptions-item>
                <el-descriptions-item label="法人或负责人联系电话">{{ dataInfo.juridicalPersonTelephone || '--' }}</el-descriptions-item>
                <el-descriptions-item label="法人登记机关">{{ dataInfo.juridicalPersonRegistration || '--' }}</el-descriptions-item>
                <el-descriptions-item label="联系人姓名">{{ dataInfo.contactName || '--' }}</el-descriptions-item>
                <el-descriptions-item label="联系方式">{{ dataInfo.contactTelephone || '--' }}</el-descriptions-item>
            </el-descriptions>
            <el-descriptions title="场所信息" colon :column="2" size="">
                <el-descriptions-item label="日托床位数量(张)">{{ dataInfo.daycareBedNumber || '--' }}</el-descriptions-item>
                <el-descriptions-item label="全托床位数量(张)">{{ dataInfo.fullcareBedNumber || '--' }}</el-descriptions-item>
                <el-descriptions-item label="护理型床位数(张)">{{ dataInfo.nursingBedNumber || '--' }}</el-descriptions-item>
                <el-descriptions-item label="床位总数(张)">{{
                    dataInfo.daycareBedNumber * 1 + dataInfo.fullcareBedNumber * 1 + dataInfo.nursingBedNumber * 1 || '--'
                }}</el-descriptions-item>
                <el-descriptions-item label="入住老年人数">{{ dataInfo.elderlyResidentNumber || '--' }}</el-descriptions-item>
                <el-descriptions-item label="服务价格类型">{{ dataInfo.servicePriceType || '--' }}</el-descriptions-item>
                <el-descriptions-item label="自理老年人收住费用（月/元）">{{ dataInfo.selfCareHousingExpense || '--' }}</el-descriptions-item>
                <el-descriptions-item label="部分失能老年人收住费用（月/元）">{{ dataInfo.partDisableHousingExpense || '--' }}</el-descriptions-item>
                <el-descriptions-item label="失能老年人收住费用（月/元）">{{ dataInfo.disableHousingExpense || '--' }}</el-descriptions-item>
                <el-descriptions-item label="城乡区划">{{ dataInfo.urbanRuralDistrict || '--' }}</el-descriptions-item>
                <el-descriptions-item label="设施建筑面积(平方米)">{{ dataInfo.buildingFacilitiesArea || '--' }}</el-descriptions-item>
                <el-descriptions-item label="占地面积(平方米)">{{ dataInfo.floorArea || '--' }}</el-descriptions-item>
                <el-descriptions-item label="机构所在地邮政编码">{{ dataInfo.institutionPostalCode || '--' }}</el-descriptions-item>
                <el-descriptions-item label="服务场所性质">{{ dataInfo.servicePlaceNature || '--' }}</el-descriptions-item>
                <el-descriptions-item label="服务类型">
                    <el-checkbox-group v-model="dataInfo.serviceType">
                        <el-checkbox label="长期托养" :disabled="dataInfo.serviceType !== '长期托养'">长期托养</el-checkbox>
                        <el-checkbox label="日间照料" :disabled="dataInfo.serviceType !== '日间照料'">日间照料</el-checkbox>
                        <el-checkbox label="上门照护" :disabled="dataInfo.serviceType !== '上门照护'">上门照护</el-checkbox>
                        <el-checkbox label="家庭养老床位" :disabled="dataInfo.serviceType !== '家庭养老床位'">家庭养老床位</el-checkbox>
                        <el-checkbox label="短期托养" :disabled="dataInfo.serviceType !== '短期托养'">短期托养</el-checkbox>
                        <el-checkbox label="助餐" :disabled="dataInfo.serviceType !== '助餐'">助餐</el-checkbox>
                    </el-checkbox-group>
                </el-descriptions-item>
            </el-descriptions>
            <el-descriptions title="服务信息" colon :column="2" size="">
                <el-descriptions-item label="全托服务人次(人次/月)">{{ dataInfo.fullCareTimes || '--' }}</el-descriptions-item>
                <el-descriptions-item label="日托服务人次(人次/月)">{{ dataInfo.dayCareTimes || '--' }}</el-descriptions-item>
                <el-descriptions-item label="老年餐桌(食堂)服务人次(人次/月)">{{ dataInfo.seniorDiningTimes || '--' }}</el-descriptions-item>
                <el-descriptions-item label="社区文化娱乐服务人次(人次/月)">{{ dataInfo.entertainmentTimes || '--' }}</el-descriptions-item>
                <el-descriptions-item label="时间银行(志愿服务)服务人次(人次/月)">{{ dataInfo.volunteerTimes || '--' }}</el-descriptions-item>
                <el-descriptions-item label="社区康复服务人次(人次/月)">{{ dataInfo.recoverTimes || '--' }}</el-descriptions-item>
                <el-descriptions-item label="其他社区养老服务服务人次(人次/月)">{{ dataInfo.otherCommunityCare_times || '--' }}</el-descriptions-item>
                <el-descriptions-item label="收住老人类型">{{ dataInfo.admitElderType || '--' }}</el-descriptions-item>
                <el-descriptions-item label="是否提供居家养老上门服务">
                    <el-radio-group v-model="dataInfo.whetherOnsiteService">
                        <el-radio label="是" :disabled="dataInfo.whetherOnsiteService !== '是'">是</el-radio>
                        <el-radio label="否" :disabled="dataInfo.whetherOnsiteService !== '否'">否</el-radio>
                    </el-radio-group>
                </el-descriptions-item>
                <el-descriptions-item label="家庭养老床位服务人次(人次/月)">{{ dataInfo.familyCareBedTimes || '--' }}</el-descriptions-item>
                <el-descriptions-item label="上门助餐服务人次(人次/月)">{{ dataInfo.onsiteMealTimes || '--' }}</el-descriptions-item>
                <el-descriptions-item label="上门助洁服务人次(人次/月)">{{ dataInfo.onsiteCleanTimes || '--' }}</el-descriptions-item>
                <el-descriptions-item label="上门助行服务人次(人次/月)">{{ dataInfo.onsiteTripTimes || '--' }}</el-descriptions-item>
                <el-descriptions-item label="上门助浴服务人次(人次/月)">{{ dataInfo.onsiteBathTimes || '--' }}</el-descriptions-item>
                <el-descriptions-item label="上门康复服务人次(人次/月)">{{ dataInfo.onsiteRecoverTimes || '--' }}</el-descriptions-item>
                <el-descriptions-item label="上门助医服务人次(人次/月)">{{ dataInfo.onsiteMedicalCare || '--' }}</el-descriptions-item>
                <el-descriptions-item label="巡访关爱服务人次(人次/月)">{{ dataInfo.visitCareTimes || '--' }}</el-descriptions-item>
                <el-descriptions-item label="是否开展医疗服务">
                    <el-radio-group v-model="dataInfo.whetherMedicalCare">
                        <el-radio label="是" :disabled="dataInfo.whetherMedicalCare !== '是'">是</el-radio>
                        <el-radio label="否" :disabled="dataInfo.whetherMedicalCare !== '否'">否</el-radio>
                    </el-radio-group>
                </el-descriptions-item>
            </el-descriptions>
            <el-descriptions title="负责人信息" colon :column="2" size="">
                <el-descriptions-item label="机构性质">{{ dataInfo.institutionalNature || '--' }}</el-descriptions-item>
                <el-descriptions-item label="机构形式">{{ dataInfo.institutionalForm || '--' }}</el-descriptions-item>
                <el-descriptions-item label="国民经济行业分类">{{ dataInfo.industryClassification || '--' }}</el-descriptions-item>
                <el-descriptions-item label="单位性质">{{ dataInfo.unitNature || '--' }}</el-descriptions-item>
                <el-descriptions-item label="兴办主体">{{ dataInfo.host || '--' }}</el-descriptions-item>
                <el-descriptions-item label="运营方式">{{ dataInfo.operationMode || '--' }}</el-descriptions-item>
                <el-descriptions-item label="是否区域养老中心">
                    <el-radio-group v-model="dataInfo.whetherPensionCenter">
                        <el-radio label="是" :disabled="dataInfo.whetherPensionCenter !== '是'">是</el-radio>
                        <el-radio label="否" :disabled="dataInfo.whetherPensionCenter !== '否'">否</el-radio>
                    </el-radio-group>
                </el-descriptions-item>
                <el-descriptions-item label="是否纳入民政统一监管">
                    <el-radio-group v-model="dataInfo.whetherCivilSupervise">
                        <el-radio label="是" :disabled="dataInfo.whetherCivilSupervise !== '是'">是</el-radio>
                        <el-radio label="否" :disabled="dataInfo.whetherCivilSupervise !== '否'">否</el-radio>
                    </el-radio-group>
                </el-descriptions-item>
                <el-descriptions-item label="土地是否由政府免费提供">
                    <el-radio-group v-model="dataInfo.landFreeProvide">
                        <el-radio label="是" :disabled="dataInfo.landFreeProvide !== '是'">是</el-radio>
                        <el-radio label="否" :disabled="dataInfo.landFreeProvide !== '否'">否</el-radio>
                    </el-radio-group>
                </el-descriptions-item>
                <el-descriptions-item label="场所由政府无偿或低偿提供">
                    <el-radio-group v-model="dataInfo.siteFreeLowCostProvide">
                        <el-radio label="是" :disabled="dataInfo.siteFreeLowCostProvide !== '是'">是</el-radio>
                        <el-radio label="否" :disabled="dataInfo.siteFreeLowCostProvide !== '否'">否</el-radio>
                    </el-radio-group>
                </el-descriptions-item>
                <el-descriptions-item label="是否由社会力量运营">
                    <el-radio-group v-model="dataInfo.whetherSocialOperate">
                        <el-radio label="是" :disabled="dataInfo.whetherSocialOperate !== '是'">是</el-radio>
                        <el-radio label="否" :disabled="dataInfo.whetherSocialOperate !== '否'">否</el-radio>
                    </el-radio-group>
                </el-descriptions-item>
                <el-descriptions-item label="是否享受养老服务税费减免">
                    <el-radio-group v-model="dataInfo.whetherPensionTaxReduction">
                        <el-radio label="是" :disabled="dataInfo.whetherPensionTaxReduction !== '是'">是</el-radio>
                        <el-radio label="否" :disabled="dataInfo.whetherPensionTaxReduction !== '否'">否</el-radio>
                    </el-radio-group>
                </el-descriptions-item>
                <el-descriptions-item label="是否注销">
                    <el-radio-group v-model="dataInfo.whetherLogout">
                        <el-radio label="是" :disabled="dataInfo.whetherLogout !== '是'">是</el-radio>
                        <el-radio label="否" :disabled="dataInfo.whetherLogout !== '否'">否</el-radio>
                    </el-radio-group>
                </el-descriptions-item>
                <el-descriptions-item label="注销日期">{{ formatDate(dataInfo.logout_date) || '--' }}</el-descriptions-item>
            </el-descriptions>
            <el-descriptions title="地图信息" colon :column="2" size="">
                <el-descriptions-item label="纬度">{{ dataInfo.latitude || '--' }}</el-descriptions-item>
                <el-descriptions-item label="经度">{{ dataInfo.longitude || '--' }}</el-descriptions-item>
            </el-descriptions>
            <!--地图-->
            <div id="map" v-show="dataInfo.longitude && dataInfo.latitude"></div>
            <span v-show="!dataInfo.longitude && !dataInfo.latitude">暂无地图信息</span>
        </div>
    </div>
</template>

<script>
import { selectServiceFacilitiesDetail } from '@/api/elderlyCareFacilities'
import AMap from 'AMap' // 引入高德地图
export default {
    name: 'elderlyCareFacilitiesDetail',
    data() {
        return {
            loading: false,
            dataInfo: {},
            point: [106.55495, 29.744899]
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        formatDate(time) {
            if (!time) return ''
            let date = new Date(time)
            let y = date.getFullYear()
            let m = date.getMonth() + 1
            let d = date.getDate()
            return y + '-' + m + '-' + d
        },
        initMap() {
            const map = new AMap.Map('map', {
                resizeEnable: true,
                zoom: 12,
                center: this.point //中心点坐标
            }) //初始化地图
            const marker = new AMap.Marker({
                position: this.point // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            })
            map.add([marker])
            map.setFitView()
        },
        getData() {
            this.loading = true
            selectServiceFacilitiesDetail({ id: this.$route.query.id * 1 })
                .then(res => {
                    this.dataInfo = res.data
                    this.dataInfo.serviceType = this.dataInfo.serviceType.split(',')
                    this.point = [res.data.longitude, res.data.latitude]
                    this.initMap()
                    this.loading = false
                })
                .catch(() => {
                    this.loading = false
                })
        }
    }
}
</script>

<style lang="scss" scoped>
.fs20 {
    font-size: 20px;
}
.contentDetail {
    background-color: #ffffff;
    // margin-top: 20px;
    padding: 20px;
    /deep/ .el-checkbox-group .is-disabled.is-checked .el-checkbox__inner {
        background-color: #3fcf89 !important;
        border-color: #3fcf89 !important;
    }
    /deep/ .el-checkbox-group .is-disabled.is-checked .el-checkbox__inner::after {
        border-color: #ffffff !important;
    }
    /deep/ .el-checkbox-group .is-disabled.is-checked .el-checkbox__label {
        color: #333333 !important;
    }
}
#map {
    width: 100%;
    height: 400px;
}
</style>
